<template>
  <div>
    <b-alert
      v-if="siaLoad"
      variant="warning"
      show
    >
      <div class="alert-body">
        <span>Memuat data <b>SIA SPBE</b>, mohon menunggu</span>
      </div>
    </b-alert>
    <b-alert
      v-if="siaFailed"
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Error Found with <b>SIA SPBE</b>
      </h4>
      <div class="alert-body d-flex justify-content-between align-items-center">
        <span v-if="siaFailedMsg != ''">
          {{ siaFailedMsg }}
        </span>
        <span v-else>Konektivitas dengan <u>SIA SPBE</u> gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi
          <u>Admin</u></span>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="btn-tour-skip mt-25 clear"
          @click="doRetry"
        >
          Coba lagi
        </b-button>
      </div>
    </b-alert>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Peta Rencana SIA SPBE
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Peta Rencana di SIA SPBE'"
          label="Nama Peta Rencana di SIA SPBE"
          label-for="vi-peta_get"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Peta Rencana di SIA SPBE"
            rules="required"
          >
            <v-select
              id="vi-peta_get"
              v-model="peta_get"
              required
              name="nama_peta_get"
              :options="siaPeta"
              placeholder="Pilih Salah Satu"
              label="name"
              @input="setSasaran()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Sasaran Strategis sesuai dengan Peta Rencana'"
          label="Nama Sasaran Strategis di Peta Rencana SIA SPBE"
          label-for="vi-sasaran_get"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Sasaran Strategis di Peta Rencana SIA SPBE"
            rules="required"
          >
            <v-select
              id="vi-sasaran_get"
              v-model="sasaran_get"
              required
              name="nama_sasaran_get"
              :options="siaSasaran"
              placeholder="Pilih Salah Satu"
              label="name"
              @input="setProgram()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Program sesuai dengan Peta Rencana'"
          label="Nama Program di Peta Rencana SIA SPBE"
          label-for="vi-program_get"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Program di Peta Rencana SIA SPBE"
            rules="required"
          >
            <v-select
              id="vi-program_get"
              v-model="program_get"
              required
              name="nama_program_get"
              :options="siaProgram"
              placeholder="Pilih Salah Satu"
              label="name"
              @input="setKegiatan()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Kegiatan sesuai dengan Peta Rencana'"
          label="Nama Kegiatan di Peta Rencana SIA SPBE"
          label-for="vi-kegiatan_get"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Kegiatan di Peta Rencana SIA SPBE"
            rules="required"
          >
            <v-select
              id="vi-kegiatan_get"
              v-model="kegiatan_get"
              required
              name="kegiatan_get"
              :options="siaKegiatan"
              placeholder="Pilih Salah Satu"
              label="name"
              @input="setItems()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <template v-if="jenis_pengadaan_id === 2">
        <b-col md="12">
          <b-form-group
            v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
            label="Domain Infrastruktur"
            label-for="vi-inf_domain_id"
          >
            <validation-provider
              #default="{ errors }"
              name="Domain Infrastruktur"
              rules="required"
            >
              <v-select
                id="vi-inf_domain_id"
                v-model="inf_domain_id"
                required
                name="inf_domain_id"
                :options="referenceData.ref_domain_infra"
                placeholder="Domain Infrastruktur"
                label="nama"
                @input="getAreaInfra()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
            label="Area Infrastruktur"
            label-for="vi-inf_area_id"
          >
            <validation-provider
              #default="{ errors }"
              name="Area Infrastruktur"
              rules="required"
            >
              <v-select
                id="vi-inf_area_id"
                v-model="inf_area_id"
                required
                name="inf_area_id"
                :options="daftarAreaInfra"
                placeholder="Area Infra"
                label="nama"
                @input="getKategoriInfra()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
            label="Kategori Infrastruktur"
            label-for="vi-inf_category_id"
          >
            <v-select
              id="vi-inf_category_id"
              v-model="inf_category_id"
              name="inf_category_id"
              :options="daftarKategoriInfra"
              :disabled="(daftarKategoriInfra.length === 0) ? true : false"
              placeholder="Kategori Infrastruktur"
              label="nama"
              @input="SetNamaKategoriInfra()"
            />
          </b-form-group>
        </b-col>
      </template>
      <b-col
        v-if="jenis_pengadaan_id !== 7"
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Item Belanja sesuai dengan Peta Rencana'"
          label="Nama Item Belanja di Peta Rencana SIA SPBE"
          label-for="vi-item_get"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Item Belanja di Peta Rencana SIA SPBE"
            rules="required"
          >
            <v-select
              id="vi-item_get"
              v-model="item_get"
              required
              name="item_get"
              :options="siaItems"
              placeholder="Pilih Salah Satu"
              label="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BRow, BCol, BFormGroup, BAlert, VBTooltip, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    vSelect,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
    clearanceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      year_budget: localStorage.getItem('tahunAnggaran'),
      userData: getUserData(),
      referenceData: {
        ref_domain_infra: [],
        ref_area_infra: [],
        ref_kategori_infra: [],
      },
      jenis_pengadaan_id: '',
      clearance_id: '',
      data_utama_id: '',
      peta_get: '',
      sasaran_get: '',
      program_get: '',
      kegiatan_get: '',
      item_get: '',
      siaLoad: false,
      siaFailed: false,
      siaFailedMsg: '',
      siaFailedLink: false,
      siaGuid: '',
      siaPeta: [],
      siaSasaran: [],
      siaProgram: [],
      siaKegiatan: [],
      siaItems: [],
      petaRencanaData: {},
      daftarAreaInfra: [],
      daftarKategoriInfra: [],
      inf_data: {},
      inf_domain_id: {
        domain_infra_id: 1,
        nama: 'Fasilitas Komputasi',
      },
      inf_area_id: {
        area_infra_id: 4,
        nama: 'Pusat Data',
      },
      inf_category_id: null,
    }
  },
  watch: {
    actionStep() {
      this.savePetaRencana()
      this.getSiaPetaRencana()
    },
    clearanceId() {
      this.getInfraReference()
    },
  },
  mounted() {
    this.jenis_pengadaan_id = Number(this.$route.params.jenis)
    // this.data_utama_id = 1229
    this.getSiaPetaRencana()
  },
  methods: {
    doRetry() {
      this.getSiaPetaRencana()
    },
    getInfraReference() {
      if (this.jenis_pengadaan_id === 2) {
        this.$http.get('/clearance/infrastruktur/references', {
          params: {
            token: localStorage.getItem('userToken'),
            data_utama_id: this.clearanceId,
            tahun_anggaran: this.coreData.year_budget,
          },
        })
          .then(res => {
            if (res.data.status === 'success') {
              this.referenceData = res.data.data

              this.getAreaInfra()
              this.inf_area_id = {
                area_infra_id: 4,
                nama: 'Pusat Data',
              }
            }
          })
      }

      return true
    },
    getAreaInfra() {
      this.daftarAreaInfra.splice(0)
      this.inf_area_id = ''
      this.inf_category_id = null
      this.item_get = ''

      const filterVal = this.inf_domain_id.domain_infra_id
      const refData = this.referenceData.ref_area_infra
      refData.map(contact => {
        if (contact.domain_infra_id === filterVal) {
          this.daftarAreaInfra.push(contact)
        }
        return true
      })
    },
    getKategoriInfra() {
      this.daftarKategoriInfra.splice(0)
      this.inf_category_id = ''
      this.item_get = ''

      const filterVal = this.inf_area_id.area_infra_id
      this.nama_infra = this.inf_area_id.nama

      const refData = this.referenceData.ref_kategori_infra
      refData.map(contact => {
        if (contact.area_infra_id === filterVal) {
          this.daftarKategoriInfra.push(contact)
        }
        return true
      })
      if (this.daftarKategoriInfra.length === 0) {
        this.getItems()
      }
    },
    SetNamaKategoriInfra() {
      this.getItems()
      this.item_get = ''
    },
    getSiaPetaRencana() {
      document.getElementById('loading-bg').style.display = 'block'
      this.siaLoad = true
      this.$http.get(`/sia/get-peta-rencana?token=${localStorage.getItem('userToken')}`)
        .then(res => {
          if (res.data.status === 'success') {
            this.siaPeta = res.data.data
            this.siaLoad = false
          } else {
            this.siaFailed = true
            this.siaLoad = false
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          this.siaFailedMsg = error.response.data.message
          this.siaFailed = true
          this.siaLoad = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    setSasaran() {
      if (!this.peta_get) {
        this.siaSasaran = []
        this.sasaran_get = ''
        this.siaProgram = []
        this.program_get = ''
        this.siaKegiatan = []
        this.kegiatan_get = ''
        this.siaItems = []
        this.item_get = ''
      } else {
        this.getSasaran()
        this.sasaran_get = ''
        this.program_get = ''
        this.kegiatan_get = ''
        this.item_get = ''
      }
    },
    getSasaran() {
      if (!this.peta_get) {
        this.sasaran_get = ''
        this.program_get = ''
        this.kegiatan_get = ''
        this.item_get = ''
      } else {
        document.getElementById('loading-bg').style.display = 'block'
        this.siaLoad = true
        const params = {
          guid: this.peta_get.guid,
        }
        this.$http.post('/sia/get-sasaran-strategis', params, {
          params: {
            token: localStorage.getItem('userToken'),
          },
        })
          .then(res => {
            if (res.data.status === 'success') {
              this.siaSasaran = res.data.data
              this.siaLoad = false
            } else {
              this.siaFailed = true
              this.siaLoad = false
            }
            document.getElementById('loading-bg').style.display = 'none'
          })
          .catch(error => {
            this.siaFailedMsg = error.response.data.message
            this.siaFailed = true
            this.siaLoad = false
            document.getElementById('loading-bg').style.display = 'none'
          })
      }
    },
    setProgram() {
      if (!this.sasaran_get) {
        this.siaProgram = []
        this.program_get = ''
        this.siaKegiatan = []
        this.kegiatan_get = ''
        this.siaItems = []
        this.item_get = ''
      } else {
        this.getProgram()
        this.program_get = ''
        this.kegiatan_get = ''
        this.item_get = ''
      }
    },
    getProgram() {
      document.getElementById('loading-bg').style.display = 'block'
      this.siaLoad = true
      const params = {
        guid: this.sasaran_get.guid,
      }
      this.$http.post('/sia/get-program', params, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.siaProgram = res.data.data
            this.siaLoad = false
          } else {
            this.siaFailed = true
            this.siaLoad = false
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          this.siaFailedMsg = error.response.data.message
          this.siaFailed = true
          this.siaLoad = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    setKegiatan() {
      if (!this.program_get) {
        this.siaKegiatan = []
        this.kegiatan_get = ''
        this.siaItems = []
        this.item_get = ''
      } else {
        this.getKegiatan()
        this.kegiatan_get = ''
        this.item_get = ''
      }
    },
    getKegiatan() {
      document.getElementById('loading-bg').style.display = 'block'
      this.siaLoad = true
      const params = {
        guid: this.program_get.guid,
      }
      this.$http.post('/sia/get-kegiatan', params, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.siaKegiatan = res.data.data
            this.siaLoad = false
          } else {
            this.siaFailedMsg = res.data.message
            this.siaFailed = true
            this.siaLoad = false
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          this.siaFailedMsg = error.response.data.message
          this.siaFailed = true
          this.siaLoad = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    setItems() {
      if (!this.kegiatan_get) {
        this.siaItems = []
        this.item_get = ''
      } else {
        this.getItems()
        this.item_get = ''
      }
    },
    getItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.siaLoad = true
      const params = {
        guid: this.kegiatan_get.guid,
      }
      let metadata = 'get-metadata-aplikasi'
      if (this.jenis_pengadaan_id === 6) {
        metadata = 'get-metadata-data'
      } else if (this.jenis_pengadaan_id === 2) {
        metadata = 'get-metadata-infrastruktur'
        params.domain_infra = this.inf_domain_id.domain_infra_id
        params.area_infra = this.inf_area_id.area_infra_id
        if (this.inf_category_id !== null) {
          params.kategori_infra = this.inf_category_id.kategori_infra_id
        }
      }
      this.$http.post(`/sia/${metadata}`, params, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.siaItems = res.data.data
            this.siaLoad = false
          } else {
            this.siaFailedMsg = res.data.message
            this.siaFailed = true
            this.siaLoad = false
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          this.siaFailedMsg = error.response.data.message
          this.siaFailed = true
          this.siaLoad = false
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    savePetaRencana() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        data_utama_id: this.clearanceId,
        guid_peta_rencana: this.peta_get.guid,
        name_peta_rencana: this.peta_get.name,
        guid_sasaran_strategis: this.sasaran_get.guid,
        name_sasaran_strategis: this.sasaran_get.name,
        guid_program: this.program_get.guid,
        name_program: this.program_get.name,
        guid_kegiatan: this.kegiatan_get.guid,
        name_kegiatan: this.kegiatan_get.name,
      }
      const kegGuidUsed = this.kegiatan_get.guid
      store.commit('app/KEG_GUID', kegGuidUsed)
      localStorage.setItem('kegGuidUsed', kegGuidUsed)

      if (this.item_get) {
        metaUtama.guid_domain = this.item_get.guid
        metaUtama.name_domain = this.item_get.name

        const guidUsed = this.item_get.guid
        store.commit('app/APP_GUID', guidUsed)
        store.commit('app/SIA_MAP', this.item_get)
        localStorage.setItem('guidUsed', guidUsed)
      }
      this.petaRencanaData = metaUtama

      this.$http.post('/sia/peta-rencana', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res && res.data.status === 'success') {
            this.data_utama_id = res.data.data.data_utama_id

            const data = {
              status: true,
              msg: metaUtama,
            }
            this.$emit('step-completed', data)
            document.getElementById('loading-bg').style.display = 'none'
          } else {
            const data = {
              status: false,
              msg: res.data.error,
            }
            this.$emit('step-completed', data)
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          document.getElementById('loading-bg').style.display = 'none'
          this.$emit('step-completed', data)
        })
    },
  },
}
</script>
